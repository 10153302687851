


















































































































































import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { MarketItemViewModel, marketViewModel } from '../viewmodels/market-viewmodel'

@Observer
@Component({
  components: {
    MarketBuyDialog: () => import('../components/market-buy-dialog.vue'),
    VestingScheduleDialog: () => import('../components/vesting-schedule-dialog.vue'),
    SuccessDialog: () => import('../components/success-dialog.vue'),
    HoverImageSmall: () => import('../components/hover-image-small.vue'),
  },
})
export default class MarketItem extends Vue {
  vm = marketViewModel
  @Prop({ required: true, default: null }) marketItem!: MarketItemViewModel

  goToItemDetail() {
    this.$router.push(`/investment-card/${this.marketItem.keyInfo.id}?chainId=${this.marketItem.poolStore.chainId}`)
  }
}
